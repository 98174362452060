import { ComponentType } from 'react';
import { ActivityResponse } from './activity';
import { MembershipInfo } from './membership-info';
import { Rank } from './rank';
import { BaseActivityDetails } from './shared';
import { SubscriptionTier } from './subscription';

export enum RAID {
  ALL = 'all',
  WHISPER = 'whisper',
  SHATTERED_THRONE = 'shatteredthrone',
  ZERO_HOUR = 'zerohour',
  PIT_OF_HERESY = 'pitofheresy',
  PROPHECY = 'prophecy',
  HARBINGER = 'harbinger',
  PRESAGE = 'presage',
  GRASP_OF_AVARICE = 'graspofavarice',
  DUALITY = 'duality',
  SPIRE_OF_THE_WATCHER = 'spireofthewatcher',
  GHOSTS_OF_THE_DEEP = 'ghostsofthedeep',
  WARLORDS_RUIN = 'warlordsruin',
  VESPERS_HOST = 'vespershost',
}

export interface RaidReportResponse<T> {
  response: T;
  error?: {
    message: string;
  };
}

export interface WorldsFirstResponse {
  instances: { [instanceId: string]: number | undefined };
}

export interface BasicRaid {
  raid: RAID;
}

export interface DisplayRaid {
  displayValue: string;
  image?: string;
}

export interface HomePageRaid extends BasicRaid, DisplayRaid {
  homePageCard?: ComponentType;
}

export interface BadgeConfiguration {
  label: string;
  tooltip: (boss: string) => string;
}

export interface WorldFirstBadgeConfiguration extends BadgeConfiguration {
  hours: number;
}

export interface BestPlayerCountBadgeConfiguration extends BadgeConfiguration {
  accountCount: number;
  flawlessTooltip: string;
}

export interface Raid extends HomePageRaid {
  boss: string;
  launchTime: string;
  versions: RaidVersion[];
  worldFirstBadgeConfiguration: WorldFirstBadgeConfiguration[];
  bestPlayerCountBadgeConfiguration: BestPlayerCountBadgeConfiguration[];
  additionalStartingPhaseIndexes?: number[];
  invalidStartingPhaseIndexes?: number[];
  flawlessRecordHash?: number;
  soloRecordHash?: number;
  soloFlawlessRecordHash?: number;
  deprecated?: boolean;
  useBungieStats?: boolean;
}

export interface RaidProps {
  raid: Raid;
}

export interface RaidVersion {
  activityHashes: number[];
  displayValue: string;
}

export interface BasePlayerCountActivityDetails extends BaseActivityDetails {
  accountCount: number;
  fresh: boolean | null;
}

export interface FirstClearActivityDetails extends BaseActivityDetails {
  period: string;
  duration: number;
  rank?: number;
}

export interface RaidReportActivity {
  activityHash: number;
  values: {
    clears: number;
    fullClears: number;
    fastestFullClear?: {
      value: number;
      instanceId: string;
    };
    sherpaCount: number;
    worldFirstDetails?: {
      rank: number;
    };
    lowAccountCountActivities?: BasePlayerCountActivityDetails[];
    flawlessActivities?: BasePlayerCountActivityDetails[];
    firstClear?: FirstClearActivityDetails;
  };
}

export interface RaidReportStats extends ActivityResponse<RaidReportActivity> {
  membershipId: string;
  clearsRank: Rank;
  speedRank: Rank;
  activeSubscriptions?: SubscriptionTier[];
}

export interface RaidReportSearchResult extends MembershipInfo {
  dateLastPlayed: string;
  emblemPath?: string;
  fullRaidClears: number;
  fullDungeonClears: number;
}
